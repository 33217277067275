/* Popup styles */
.popup1 {
    position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        display: flex;
        justify-content: center;
        align-items: center;
}

.popup-content1 {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 50%;

    max-height: 80%;

    overflow-y: auto;
   
}

.popup h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.popup p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 15px;
}

.popup button {
    padding: 8px 16px;
    background-color: #007bff;

    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.popup button:hover {
    background-color: #0056b3;

}

.slide-in-bottom {
    animation: slideInBottomAnimation 0.5s ease-in-out forwards;
    opacity: 0;
    transform: translateY(50px);
}

@keyframes slideInBottomAnimation {
    from {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}