.ratings-list {
  list-style-type: none;
  padding: 0;
}

.rating-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.rating-item:last-child {
  border-bottom: none;
}

.booking-id {
  font-weight: bold;
}


body {
  margin-top: 20px;
  background-color: #f2f6fc;
  color: #69707a;
}

.img-account-profile {
  height: 10rem;
}

.rounded-circle {
  border-radius: 50% !important;
}

.card {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(12, 53, 236, 0.15);
}

.card .card-header {
  font-weight: 500;
}

.card-header:first-child {
  border-radius: 0.35rem 0.35rem 0 0;
}

.card-header {
  padding: 1rem 1.35rem;
  margin-bottom: 0;
  background-color: rgba(33, 40, 50, 0.03);
  border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}

.form-control,
.dataTable-input {
  display: block;
  width: 100%;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #69707a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c5ccd6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nav-borders .nav-link.active {
  color: #0061f2;
  border-bottom-color: #0061f2;
}

.nav-borders .nav-link {
  color: #69707a;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem;
}

.btn {
  background-color: #0061f2;
  color: #ffffff;
  border-color: #0061f2;
}

.custom-card {
  margin: 0 auto;

}

.custom-card .card-body {
  width: 80%;

  margin: 0 auto;

}

.card-header {
  background-color: #ffffff;
 
}



.chart-container {
  width: calc(50% - 20px); 
  min-width: 1300px; 
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.chart-container canvas {
  width: 100%;
  height: auto;
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.chart-container h2 {
  margin-bottom: 15px;
  font-size: 1.5rem;
}
.analytics h1 {
  margin-top: 200px; 
  padding: 15px; 
  text-align: center; 
  font-size: 2rem; 
  margin-bottom: -90px;
}

.H1 {
  text-align: center;
  margin-top: 20px;
  text-align: center;
  color: black;
}

.overview-list {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.overview-list-item {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.overview-list-item:hover {
  background-color: #f0f0f0;
  border-color: #999;
}

.overview-list-item.selected {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.overview-list-item.selected:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}


.overview-container {
  width: calc(50% - 20px); 
  min-width: 250px; 
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.overview-container h2 {
  margin-bottom: 20px;
}

.overview-container ul {
  list-style: none;
  padding: 0;
}

.overview-container li {
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.overview-container li:last-child {
  border-bottom: none;
}

.overview-container li strong {
  display: inline-block;
  width: 150px;
  color: #333;
}

.overview-container li span {
  display: block;
  margin: 5px 0;
  color: #555;
}

.overview-container li span strong {
  display: inline-block;
  width: auto;
}

@media (min-width: 768px) {
  .overview-container li span {
    display: inline;
    margin-right: 20px;
  }
}

.mini-overview {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.mini-overview-item {
  width: calc(50% - 20px); 
  min-width: 250px; 
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .mini-overview {
    flex-direction: column;
    align-items: center;
  }

  .mini-overview-item {
    width: 100%; 
    margin-bottom: 10px;
  }
}

.pie-chart {
  width: calc(50% - 20px); 
  min-width: 250px; 
  padding: 20px;
  background-color: #e0dddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.text-display {
  margin-top: 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.text-display h2 {
  margin-top: 100px;
  margin-bottom: 10px;
  color: #00264d;
}

.text-display p {
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #0056b3;
}

.mini-overview {
display: flex;
flex-wrap: wrap;
gap: 20px; 
}

.overview-item {
background-color: #ffffff;
padding: 20px;
border-radius: 8px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
width: calc(50% - 10px); 
}

.overview-item h3 {
font-size: 1.2rem;
margin-bottom: 10px;
}

.overview-item p {
font-size: 1rem;
margin: 0;
}
.tog-buttons {
  display: flex;
  justify-content: center; 
  margin: 20px 0; 
}

.tog-buttons button {
  background-color: #007bff; 
  color: white; 
  border: none; 
  border-radius: 5px; 
  padding: 10px 20px; 
  margin: 0 5px; 
  cursor: pointer; 
  transition: background-color 0.3s, transform 0.2s; 
}

.tog-buttons button:hover {
  background-color: #0056b3; 
  transform: scale(1.05); 
}

.tog-buttons button:focus {
  outline: none; 
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); 
}
.analytics-container {
  font-family: Arial, sans-serif;
}



.analytics-title {
  text-align: center;
  margin: 20px 0;
}

.view-toggle {
  display: flex;
  justify-content: center;
  margin: 0 auto 20px auto; 
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  width: 1900px;
  margin-top: 100px;
}


.view-toggle button {
  flex: 1;
  background-color: white;
  color: #1e90ff;
  border: 1px solid #1e90ff;
  border-left: none;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.view-toggle button:first-child {
  border-left: 1px solid #0d09e6;
}

.view-toggle button.selected {
  background-color: #345fec23;
  color: white;
}

.view-toggle button:hover {
  background-color: rgba(39, 58, 238, 0.767);
  color: white; 
}


.charts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px;
}

.chart {
  flex: 1 1 45%; 
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #f9f9f9; 
  width: 1900px;
  margin: 0 auto 20px auto;
  border: 1px solid #1e90ff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.summary {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.summary-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; 
  margin-top: 20px; 
  
}

.pie-chart {
  width: 50%; 
  margin-right: 20px; 
  margin-left: 100px;
  margin-top: 20px;
  border: 1px solid #1e90ff;
}

.summary {
  width: 50%; 
  height: 940px;;
  margin-right: 100px;
  margin-bottom: 20px;
  border: 1px solid #1e90ff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  
}
.booking-status {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #1e90ff;
  border-radius: 5px;
  text-align: center;
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.status-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
  
}

.status-box {
  background-color: #f0f0f0;
  border: 1px solid #1e90ff;
  border-radius: 5px;
  padding: 10px;
  min-width: 300px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.status-box:hover {
  background-color: #add8e6;
}

.approved {
  background-color: #e6e6e69a;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.pending {
  background-color: #e6e6e69a;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
 
}

.completed {
  background-color: #e6e6e69a;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.canceled {
  background-color: #e6e6e69a;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.refunded {
  background-color: #e6e6e69a;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.dates-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
}

.dates-popup h4 {
  color:  #302b63;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #302b63;
  font-size: 1.2rem;
}

.dates-popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dates-popup li {
  padding: 10px;
  margin: 5px 0;
  background-color: #f8f9fa;
  border: 1px solid #1e90ff;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.dates-popup li:hover {
  background-color: #e9ecef;
}



.dates-popup button{
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 10px 38px 11px 38px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: #302b63;
}

/* Add overlay background */

/* Scrollbar styling */
.dates-popup::-webkit-scrollbar {
  width: 8px;
}

.dates-popup::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.dates-popup::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.dates-popup::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -48%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.dates-popup {
  animation: fadeIn 0.3s ease-out;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dates-popup {
    width: 95%;
    max-height: 70vh;
  }
  
  .dates-popup h4 {
    font-size: 1.1rem;
  }
  
  .dates-popup li {
    padding: 8px;
    font-size: 0.9rem;
  }
}

.analytics-filters-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  position: absolute;
  right: 20px;
  top: 120px;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.date-filters {
  display: flex;
  gap: 10px;
}

.date-filters select {
  padding: 0.5rem;
  border: 1px solid #1e90ff;
  border-radius: 4px;
  background-color: white;
  min-width: 120px;
  cursor: pointer;
  font-size: 14px;
}

.export-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.export-button {
  padding: 8px 16px;
  background-color: #302b63;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.export-button:hover {
  background-color: #0056b3;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

@media (max-width: 768px) {
  .analytics-filters-container {
    position: static;
    margin: 20px;
    flex-direction: column;
    align-items: stretch;
  }

  .date-filters {
    flex-direction: column;
  }

  .export-buttons {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .export-button {
    flex: 1;
    min-width: 150px;
  }
}

