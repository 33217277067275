.container {
    width: 100%; 
    max-width: 1700px; 
    margin: 0 auto; 
    padding: 0 15px; 
}
.status-red {
    color: red;
}
  
.status-green {
    color: green;
}


.btn-fixed-height {
    height: 50px; 
    
}

.text-center {
    text-align: center;
}

.booking-table table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    table-layout: auto;
   
}
.booking-table{
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    table-layout: auto;
    overflow-x: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    border: 1px solid #ccc;
    border-radius: 8px;
}
.booking-table th,
.booking-table td {
    border: 2px solid #000000;
    text-align: left;
    padding: 8px;
    color: black;
    
}

.booking-table td {
    background-color: #c3b5ff;
}


.booking-table td.status-red
{
   color: red;
}

.booking-table td.status-green {
    color: green;
}






.Abooking-table table {
    width: 100%;
    max-width: 150%;
    overflow-x: auto;
   
   
    
}
.Abooking-table {
    width: 100%;
    max-width: 100%;
    table-layout: auto;
    overflow-x: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    border: 1px solid #ccc;
     border-radius: 8px;

}
.Abooking-table th,
.Abooking-table td {
    border: 2px solid #000000;
    text-align: left;
    padding: 8px;
    color: black;
    overflow-x: auto; 
}

.Abooking-table td {
    background-color: #c4ffe6;
}


.Abooking-table td.status-red
{
   color: red;
}

.Abooking-table td.status-green {
    color: green;
}





  
.booking-table td {
    font-size: 12px;
}

.Abooking-table td {
    font-size: 12px;
   
    overflow-x: auto; 
   
}




.apply-button {
    margin-bottom: 20px;
  }
  
  .bookings-table {
    margin-top: 20px;
  }
  
 
  
  .action-button {
 
    min-width: 100px; 
    max-width: 100px; 
    text-align: top; 
  }


  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }


  .underline {
    color: black;
    text-decoration: underline;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .underline:hover {
    color: white;
  }






.modal {
    display: block; 
    position: center;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    animation: fadeIn 0.3s ease; 
  }
  
  .modal-content {
    background-color: #c49ff3;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border: 1px solid #020202;
    width: 80%;
    max-width: 500px; 
    color: rgb(0, 0, 0);
  }

  .star-rating {
    display: flex;
    align-items: center;
}

.star {
    font-size: 24px;
    margin-right: 5px;
}

.star.filled {
    color: #FFD700;
}


  .modal-content1 {
    background-color: #c49ff3;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border: 1px solid #020202;
    width: 80%;
    max-width: 700px;
    color: rgb(0, 0, 0);
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  


  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  








body {
    margin-top: 20px;
    background-color: #f2f6fc;
    color: #69707a;
}

.img-account-profile {
    height: 10rem;
}

.rounded-circle {
    border-radius: 50% !important;
}

.card {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(12, 53, 236, 0.15);
}

.card .card-header {
    font-weight: 500;
}

.card-header:first-child {
    border-radius: 0.35rem 0.35rem 0 0;
}

.card-header {
    padding: 1rem 1.35rem;
    margin-bottom: 0;
    background-color: rgba(33, 40, 50, 0.03);
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}

.form-control,
.dataTable-input {
    display: block;
    width: 100%;
    padding: 0.875rem 1.125rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    color: #69707a;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c5ccd6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nav-borders .nav-link.active {
    color: #0061f2;
    border-bottom-color: #0061f2;
}

.nav-borders .nav-link {
    color: #69707a;
    border-bottom-width: 0.125rem;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
    margin-right: 1rem;
}

.btn {
    background-color: #0061f2;
    color: #ffffff;
    border-color: #0061f2;
}

.custom-card {
    margin: 0 auto;
  
}

.custom-card .card-body {
    width: 80%;
   
    margin: 0 auto;
    
}

.card-header {
    background-color: #ffffff;
    
}




.user-avatar-link img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.dropdown-menu {
    left: auto !important;
    right: 0;
}

.user-avatar {
    border-radius: 50%;
}


.nav-link {
    margin-right: 10px;
    padding: 10px 15px;
}

@media (max-width: 768px) {
    .navbar-nav .nav-item {
        text-align: center;
        padding: 10px 0;
    }

    .navbar-nav .nav-item .nav-link {
        padding: 10px 15px;
    }
}

.nav-link.scrollto .notification-button {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.nav-link.scrollto .notification-icon {
  font-size: 20px;
}