src/pages/Booking.css
.navbar {
    padding: 0;

}
  
.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.package-image {
    animation: fadeIn 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.navbar li {
    position: relative;
}

.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    color: #47b2e4;
}

.navbar .getstarted,
.navbar .getstarted:focus {
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    border: 2px solid #47b2e4;
    font-weight: 600;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
    color: #fff;
    background: #31a9e1;
}

.mobile-nav-toggle {
    position: absolute;
    top: 20px;
    left: 100px;
    z-index: 999;
}

.mobile-nav-toggle.bi-x {
    color: #fff;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(40, 58, 90, 0.9);
    transition: 0.3s;
    z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 0;
    left: 0;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
    max-height: calc(100vh - 70px);
    width: 100%;
    border-radius: 0;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #37517e;
}

.navbar-mobile .active {
    color: #47b2e4;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
    color: #37517e;
}

.navbar-mobile .dropdown {
    position: relative;
    right: 50px;
    visibility: visible;
}

.navbar-mobile .dropdown ul {
    position: absolute;
    left: 0;
    top: 100%;
    margin: 0;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    border-radius: 4px;
    height: 200px;
    visibility: visible;
    opacity: 1;
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    text-transform: none;
    font-weight: 500;
    color: #0c3c53;
}

.navbar-mobile .dropdown-container {
    position: relative;
}

.navbar-mobile .dropdown-menu {
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    border-radius: 4px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 99;
}

@media (max-height: 600px) {
    .navbar-mobile .dropdown-menu {
        max-height: calc(100vh - 60px);
        overflow-y: auto;
    }
}

.booking form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
}

.booking form select:focus {
    outline: none;
    border-color: #4caf50;
}

.booking .section-title {
    padding: 60px 100px 0 100px;
}

@media (max-width: 1024px) {
    .booking .section-title {
        padding: 40px 50px 0 50px;
    }
}

@media (max-width: 768px) {
    .booking .section-title {
        padding: 30px 20px 0 20px;
    }
}

@media (max-width: 480px) {
    .booking .section-title {
        padding: 20px 10px 0 10px;
    }
}

.booking {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.booking h2 {
    margin-bottom: 15px;
    font-size: 24px;
    color: #333;
}

.booking label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.booking input[type="text"],
.booking input[type="email"],
.booking input[type="date"],
.booking input[type="number"],
.booking select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.booking button {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 2px;
    padding: 20px 58px 21px 58px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: #302b63;
}

.booking button:hover {
    background: #EE9B12;
}

.Booking-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 60px;
    justify-items: center;
}

.Booking-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.Booking-list li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: none;
    border-radius: 16px;
    position: relative;
    width: 100%;
    min-height: 600px;
    height: auto;
    background: linear-gradient(135deg, #f9f9f9, #e0e0e0);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1),
                0 6px 6px rgba(0, 0, 0, 0.05),
                0 0 100px rgba(0, 0, 0, 0.03);
    transition: all 0.3s ease;
    cursor: pointer;
    margin-bottom: 50px;
}

.Booking-list li img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 12px;
    margin: auto;
}

.Booking-list li h3 {
    margin-bottom: 20px;
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.Booking-list span {
    display: block;
    margin-bottom: 15px;
    padding: 8px;
    word-wrap: break-word;
    color: #444;
    font-size: 1.1em;
    line-height: 1.6;
}

.Booking-list span:last-of-type {
    margin-bottom: 25px;
}

.Booking-list li button {
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #302b63;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;
}

.Booking-list span {
    display: block;
    margin-bottom: 5px;
}

.Booking-list button {
    display: block;
}

.Select-button {
    width: 100px;
    height: 40px;
    border-radius: 60px / 50.5px;
    background-color: #302b63;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    transform: translateX(-20%);
    z-index: 1;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.Select-button:hover {
    background-color: #EE9B12;
}

.Package-list {
    position: relative;
    top: calc(100% + 10px);
    left: 0;
    width: 150px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 2;
}

.Select-button:hover + .Package-list {
    display: block;
}

.map-container {
    width: 100%;
    height: 400px;
}

button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #45a049;
}

@media (max-width: 900px) {
    .map-container {
        height: 300px;
    }

    button {
        width: 100%;
        padding: 15px;
        font-size: 16px;
    }
}
.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}


.popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 300px;
}

.popup-content p {
    margin: 0 0 20px;
}

.popup-content button {
    margin-left: 100px;
    width: 100px;
    height: 40px;
    border-radius: 60px / 50.5px;
    background-color: #302b63;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    transform: translateX(-20%);
    z-index: 1;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.leaflet-control-attribution {
    opacity: 0.5; 
    font-size: 10px; 
    position: absolute !important;
    bottom: 5px !important; 
    left: 5px !important;
}
.Booking-form {
    max-width: 700px;
    margin: 0 auto;
    border-radius: 0.5rem;
    padding: 1.5rem;
    background-color: white;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.2), 
                0 10px 10px -5px rgba(0, 0, 0, 0.15),
                0 0 15px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .Booking-form {
      padding: 30px;
      width: 300px;
      margin: 0 auto;
      border-radius: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  @media (max-width: 768px) {
    .Booking-form {
      padding: 10px;
    }
  
    .Booking-form input,
    .Booking-form select,
    .Booking-form textarea {
      font-size: 1rem;
    }
  
    .Booking-form label {
      font-size: 1.1rem;
    }
  
    .Booking-form button {
      width: 100%;
      margin-top: 20px;
    }
  }
