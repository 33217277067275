/* Custom CSS */
.custom-label {
    font-weight: bold;
}



/* manageAccounts.css */

/* Modal User Styles */
.modal-user {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    z-index: 1000; /* Ensure it appears above other content */
}

.modal-user-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 90%; /* Increased width for a wider modal */
    max-width: 1500px; /* Maximum width increased */
    max-height: 80%; /* Maximum height for scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative; /* For positioning the close button */
}

.modal-user-content h2 {
    margin-top: 0; /* Remove default margin */
}

.modal-user .close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
    color: #333; /* Close button color */
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px; /* Space above the table */
}

th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd; /* Table row separator */
}

th {
    background-color: #f2f2f2; /* Header background color */
}

tr:hover {
    background-color: #f1f1f1; /* Row hover effect */
}

.custom-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 4px;
    margin-bottom: 10px;
}

.custom-left-align {
    display: flex;
    justify-content: flex-start;
}

.custom-right-align {
    display: flex;
    justify-content: flex-start;
}


.btn-add {
    font-size: 24px;
    width: 80px;
    height: 80px;
    border-color: none;
    margin-top: 350px;

}