#hero {
    width: 100%;
    height: 80vh;
    background: url('../../public/assets/img/officeETM.png');
  
    background-size: cover;

    background-repeat: no-repeat;
 
    background-position: center;
   
}

.cookie-consent-banner {
  text-align: center;
  margin: 20px 0;
  background-color: #f8f9fa; 
  padding: 15px; 
  border-radius: 5px; 

}

#hero .container {
    padding-top: 72px;
}

#hero h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: white;
}

#hero h2 {
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 50px;
    font-size: 24px;
}

#hero .btn-get-started {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 11px 28px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px 0 0 0;
    color: #fff;
    background: #220af5;
}

#hero .btn-get-started:hover {
    background: #EE9B12;
}

#hero .btn-visit {
    font-size: 16px;
    display: flex;
    align-items: center;
    transition: 0.5s;
    margin: 10px 0 0 25px;
    color: #fff;
    line-height: 1;
}

#hero .btn-visit i {
    line-height: 0;
    color: #fff;
    font-size: 32px;
    transition: 0.3s;
    margin-right: 8px;
}

#hero .btn-visit:hover i {
    color: #47b2e4;
}

#hero .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 991px) {
    #hero {
        height: 100vh;
        text-align: center;
    }

    #hero .animated {
        animation: none;
    }

    #hero .hero-img {
        text-align: center;
    }

    #hero .hero-img img {
        width: 50%;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    #hero .hero-img img {
        width: 70%;
    }
}

@media (max-width: 575px) {
    #hero .hero-img img {
        width: 80%;
    }

    #hero .btn-get-started {
        font-size: 16px;
        padding: 10px 24px 11px 24px;
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}


