.booking button {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 2px;
    padding: 20px 58px 21px 58px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: #302b63;
}

.booking button:hover {
    background: #EE9B12;
}

.package-list {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 60px;
    margin: 0 auto;
    max-width: 1200px;
    width: 95%;
}

.package-list li {
    background: white;
    border: none;
    border-radius: 15px;
    padding: 40px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: grid;
    grid-template-columns: 400px 1fr;
    gap: 30px;
    position: relative;
    overflow: hidden;
}

.package-list li:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.package-list .package-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 12px;
}

.package-list .package-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.package-list .package-title {
    font-size: 2rem;
    font-weight: 600;
    color: #302b63;
    margin-bottom: 15px;
}

.package-list .package-price {
    font-size: 1.6rem;
    color: #EE9B12;
    font-weight: 600;
    margin-bottom: 20px;
}

.package-list .package-description {
    color: #666;
    margin-bottom: 20px;
    line-height: 1.6;
    font-size: 1.1rem;
}

.package-list .package-details {
    margin-bottom: 30px;
}

.package-list .package-details span {
    display: block;
    margin-bottom: 12px;
    color: #555;
    font-size: 1.1rem;
}

.Book-button {
    width: auto;
    min-width: 200px;
    padding: 15px 30px;
    border-radius: 25px;
    background-color: #302b63;
    color: white;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    align-self: flex-start;
}

.Book-button:hover {
    background-color: #EE9B12;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(238, 155, 18, 0.3);
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .package-list li {
        grid-template-columns: 1fr;
        padding: 30px;
    }

    .package-list {
        padding: 30px;
    }

    .Book-button {
        align-self: center;
    }
}

@media (max-width: 768px) {
    .package-list {
        padding: 20px;
    }

    .package-list li {
        padding: 20px;
    }

    .package-list .package-title {
        font-size: 1.6rem;
    }

    .package-list .package-price {
        .Package-list {
            position: relative;
            top: calc(100% + 10px);
            left: 0;
            width: 150px;
            background-color: #fff;
            border: 1px solid #ccc;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            display: none;
            z-index: 2;
        }
        
        .Select-button:hover + .Package-list {
            display: block;
        }
        font-size: 1.4rem;
    }
}
.package-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.package-links a {
  color: #302b63;
  text-decoration: none;
  padding: 5px 10px;
  border: 1px solid #302b63;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.package-links a:hover {
  background: #302b63;
  color: white;
}